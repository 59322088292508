import createSafeActionWrapper from '../../utils/create-safe-action-wrapper.js';

export const detectContentTypeSuccess = createSafeActionWrapper(
  (oriAction, system) =>
    ({ content }) => {
      const { specActions, editorSelectors, editorPreviewSwaggerUISelectors } = system;

      const contentOrigin = editorSelectors.selectContentOrigin();

      // all content in editor was deleted
      if (contentOrigin === 'editor' && !content.trim()) {
        specActions.updateUrl('');
      }

      if (editorSelectors.selectIsContentTypeOpenAPI()) {
        if (contentOrigin === 'import-url') {
          specActions.updateUrl(editorPreviewSwaggerUISelectors.selectURL());
        } else if (contentOrigin !== 'editor') {
          specActions.updateUrl('');
        }

        specActions.updateSpec(content, 'swagger-editor');
      }
    }
);

export const previewUnmounted = createSafeActionWrapper((oriAction, system) => () => {
  system.specActions.updateUrl('');
  system.specActions.updateSpec('', 'swagger-editor');
});
